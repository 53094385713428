<template>
    <div class="department_container">
        <div class="table-search">
            <div class="search-L">
                <!-- <el-select v-model="tableParams.deptStatus" placeholder="请选择状态" clearable @change="queryDeptList"
                    class="mr15">
                    <el-option v-for="item in ObjToOpt(departmentStatus)" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select> -->
            </div>
            <div class="search-R">
                <el-button type="primary" @click="add()">新增部门</el-button>
            </div>
        </div>
        <el-table :data="tableData" row-key="deptId" border default-expand-all
            :tree-props="{ children: 'childDeptVoList' }" :header-cell-style="{
                background: '#FAFAFA', color: '#000000'
            }">
            <el-table-column prop="deptName" label="部门名称">
            </el-table-column>
            <el-table-column prop="deptTop" label="排序" align="center">
            </el-table-column>
            <el-table-column prop="deptStatus" label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.deptStatus == 0" type="success">正常</el-tag>
                    <el-tag v-else type="danger">停用</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="deptAdminNames" label="部门主管">
            </el-table-column>
            <el-table-column width="200" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="medium" @click.stop="edit(scope.row)" v-if="1 == true">编辑</el-button>
                    <el-button type="text" size="medium" @click="del(scope.row)"
                        v-if="scope.row.parentId != 0">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 新增部门 -->
        <el-dialog :visible.sync="editShow" :title="title" :close-on-click-modal="false" width="30%" center @close="closeDialog('form-ref')">
            <el-form :model="formData" :rules="rules" ref="form-ref" label-width="120px">
                <el-form-item label="上级部门" prop="parentId" v-if="formData.parentId !== 0">
                    <el-cascader v-model="formData.parentId" :options="options" placeholder="请选择部门"
                        :props="cascaderProps"></el-cascader>
                </el-form-item>
                <el-form-item label="部门名称" prop="deptName">
                    <el-input v-model.trim="formData.deptName" maxlength="20" placeholder="请输入部门名称"></el-input>
                </el-form-item>
                <el-form-item label="部门排序" prop="deptTop">
                    <el-input v-model.trim="formData.deptTop" autocomplete="off" maxlength="8" placeholder="请输入排序值"
                        oninput="value=value.replace(/[^\d]/g,'')" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="部门状态" prop="deptStatus">
                    <el-radio-group v-model="formData.deptStatus">
                        <el-radio :label="item.value" :key="item.value" v-for="item in ObjToOpt(departmentStatus)">{{
                            item.label
                        }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="部门主管" prop="adminUserIds">
                    <el-select v-model="formData.adminUserIds" placeholder="请设置主管" :multiple="true" clearable>
                        <el-option v-for="item in userDeptVoList" :key="item.userId" :label="item.userName"
                            :value="item.userId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit('form-ref')">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { departmentStatus } from "@/db/objs.js";
import { ObjToOpt } from "@/utils/utils";
import { addDept, deleteDept, updateDept, queryDept } from "@/api/organization/department.js";
export default {
    data() {
        return {
            departmentStatus, ObjToOpt,
            editShow: false,
            title: "新增部门",
            tableParams: {
                deptStatus: "",
            },
            rules: {
                parentId: [{ required: true, message: "请选择上级部门", trigger: "blur" }],
                deptName: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
                deptTop: [{ required: true, message: "请输入部门排序", trigger: "blur" }],
                deptStatus: [{ required: true, message: "请选择部门状态", trigger: "blur" }],
                leaderId: [{ required: true, message: "请选择部门主管", trigger: "blur" }],
            },
            formData: {
                parentId: "",
                deptName: "",
                deptStatus: 0,
                deptTop: '',
                adminUserIds: [],
            },
            tableData: [],
            userDeptVoList: [],
            value: [],
            cascaderProps: {
                value: 'deptId',
                label: 'deptName',
                children: 'childDeptVoList',
                emitPath: false,
                checkStrictly: true
            },
            options: []

        }
    },
    created() {
        this.queryDeptList();
    },
    methods: {
        add() {
            this.title = "新增部门";
            this.editShow = true;
            this.detailDeptList();

            this.formData = {
                parentId: "",
                deptName: "",
                deptStatus: 0,
                deptTop: '',
                adminUserIds: [],
            };
        },
        edit(row) {
            this.title = "编辑部门";
            this.editShow = true;
            this.detailDeptList(row.deptId);
            this.formData = JSON.parse(JSON.stringify(row));
            this.userDeptVoList = row.userDeptVoList;
        },
        del(row) {
            this.$confirm('删除后请及时修改原挂载在该部门下的员工归属, 是否删除该部门?', '删除部门', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteDept({ deptId: row.deptId }).then(res => {
                    if (res.isSuccess == 'yes') {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.queryDeptList();
                    }
                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 查询部门列表
        queryDeptList(type) {
            queryDept(this.tableParams).then(res => {
                this.tableData = [res.data];
                
            });
        },
         // 点编辑查询部门列表
         detailDeptList(id) {
            queryDept({ deptId: id }).then(res => {
                this.options = [res.data];
            });
        },
        // 新增部门
        addDept() {
            addDept(this.formData).then(res => {
                if (res.isSuccess == 'yes') {
                    this.$message({
                        type: 'success',
                        message: '新增成功!'
                    });
                    this.editShow = false;
                    this.queryDeptList();
                }
            });
        },
        updateDept() {
            updateDept(this.formData).then(res => {
                if (res.isSuccess == 'yes') {
                    this.$message({
                        type: 'success',
                        message: '编辑成功!'
                    });
                    this.editShow = false;
                    this.queryDeptList();
                }
            });
        },
        // 编辑部门
        onSubmit:_.debounce(function(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.formData.deptId) {
                        this.updateDept();
                    } else {
                        this.addDept();
                    }

                } else {
                    return false;
                }
            });
        },500),
   
        closeDialog(formName){
            this.$refs[formName].resetFields();
        }
    },
}
</script>
<style lang="scss" scoped>
.department_container {
    padding: 0.15rem;
    box-sizing: border-box;

    .table-search {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.2rem;

        .search-L {
            display: flex;
        }
    }

    .table-footer {
        margin-top: 0.1rem;
        display: flex;
        justify-content: flex-end;
    }
}
</style>